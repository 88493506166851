<template>
  <div class="row">

    <div class="col-md-8">
      <Print :data="data" :setting="setting" />
    </div>

    <!-- PDF -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="`${data.po_no}-${data.vendor_name}`"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <Print :data="data" />
      </section>
    </vue-html2pdf>

    <div class="col-md">
      <b-button
        squared
        class="mt-2"
        size="sm"
        variant="primary"
        @click="btnPrintOnClick"
      >Print</b-button>
      <b-button
        squared
        :class="'d-block mt-2'"
        size="sm"
        variant="info"
        @click="btnDownloadOnClick"
      >Download</b-button>
      <b-button
        squared
        :class="'d-block mt-2'"
        size="sm"
        variant="success"
        v-if="manipulateBtn == true"
        @click="$router.push('/purchase-orders/edit/' + data.id)"
      >Edit Pesanan Surat (PO) Klinik</b-button>
      <b-button
        squared
        :class="'d-block mt-2'"
        size="sm"
        variant="success"
        v-if="manipulateBtn == true"
        @click="$router.push('/purchase-orders/add')"
      >Tambah Baru</b-button>
    </div>

  </div>
</template>

<script>

import Print from '@/component/purchase-orders/Print.vue'
import module from '@/core/modules/CrudModule.js'
import VueHtml2pdf from "vue-html2pdf"
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Print,
    VueHtml2pdf
  },

  data() {
    return {
      // Data
      data: {},
      setting: {},
      manipulateBtn:false
    }
  },

  methods: {

    async getSetting() {
      this.setting = await module.get('website-settings/get-default')
      if(this.setting == null){
        this.setting = {
          address: "Jl. Kramat 6 No.11, RT.2/RW.1, Kenari, Kec. Senen, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10430",
          emergency: "(021) 3909333"
        }
      }
    },

    async get() {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

      this.data = await module.get(`purchase-orders/${this.$route.params.id}`)
      let data = new Date(this.data.date)
      let localdata = new Date(this.data.date).toLocaleDateString("id-ID", options)

      this.data.date = localdata.split(",")[1]
      // If Data Not found
      if (this.data == null) {
        // Redirect To List
        this.$router.push('/purchase-orders')
      }
    },

    btnPrintOnClick() {
      this.$htmlToPaper('print-lab-report')
    },

    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf()
    },

    // access management
    async setActiveMenu(){

     let access_right_user = window.localStorage.getItem("access_right_display")
     let access_right = JSON.parse(access_right_user)
    
    let a
    for(a = 0; a < access_right.length; a++){
      
      
      if(access_right[a] == "6022"){
        this.manipulateBtn = true
      }
      
    }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Surat Pesanan (PO) Klinik", route: "/purchase-orders" },
      { title: "Detail Surat Pesanan (PO) Klinik"},
    ])
    // Get Data
    this.get()
    this.getSetting()
    this.setActiveMenu()
  },

}
</script>

<style>
</style>